.preview {
  position: absolute;
  top: 200px;
  right: 540px;
  z-index: 5;
  width: 300px;
  min-height: 400px;
  border: 1px solid #000;
  background-color: #fff;
}

.container {
  height: 100vh;
  width: 540px;
  box-sizing: border-box;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
  transition: width 0.2s ease-in;
}

.minimized {
  width: 200px;
}
