.container {
  padding-bottom: 500px;
}

.image {
  cursor: pointer;
  width: 100px;
  user-select: none
}

.image:hover {
  opacity: 0.5;
}

.imageSelected {
  border: 4px solid #007bff;
}

.row {
  margin: 5px 0;
}

.selectedRow {
  border: 1px solid #000;
  border-left: none;
  border-right: none;
}

.choosenRow {
  border: 8px solid #28a745;
}

.imagesRow {
  display: flex;
  align-items: center;
  height: 150px;
  overflow-x: hidden;
}

.rowBrands {
  font-size: 14px;
}

.similar {
  box-shadow: 0px 0px 5px #007bff;
  margin: 0 5px;
}

.similarRow {
  box-shadow: 0px 0px 3px #007bff;
}

.invisible {
  display: none;
}
