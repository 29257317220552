.container {
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  bottom: 100%;
  list-style-type: none;
  min-width: 200px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  padding: 8px 0;
  margin: 0;
  z-index: 10;
}

.short {
  max-height: 250px;

  bottom: auto;
  top: 38px;
  left: -205px;
}

.word {
  white-space: nowrap;
  cursor: pointer;
  color: #6c757d;
  padding: 8px;
}

.word:hover {
  background-color: #f8f9fa;
}

.word:hover {
  opacity: 0.5;
}

.used {
  color: #bb2d3b;
}

.hidden {
  visibility: hidden;
}
