.suggest {
  position: absolute;
  min-width: 400px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ced4da;
  top: 38px;
  box-sizing: border-box;
  padding: 8px 0;
  z-index: 10;
  white-space: nowrap;
}

.option {
  cursor: pointer;
  padding: 8px;
}

.keyboard {
  background-color: #b5ecf7;
}
