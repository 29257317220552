.container {
  display: flex;
  flex-wrap: nowrap;
}

.image {
  height: 100px;
  position: relative;
  z-index: 3;
}

.image:hover {
  z-index: 1;
}
