.container {
  display: flex;
  gap: 3px;
  background-color: rgba(255, 255, 255, .8);
  border-radius: 6px;
  padding: 5px;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
  opacity: .3;
}

.active {
  opacity: 1;
}
