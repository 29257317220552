.pageContent {
  padding: 80px 0 20px;
}

.menuLinkItem {
  text-decoration: none;
  color: inherit;
}

.menuLinkItem:hover {
  color: inherit;
}
