.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
}

.button:hover {
  cursor: pointer;
  opacity: 1;
  transition: opacity .2s ease-in;
}

.arrow {
  color: #fff;
}

.disabled {
  visibility: hidden;
}
