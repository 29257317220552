.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-around;
}

.mainImage {
  transition: all .1s ease-in;
  max-height: 50vh;
}

.colors {
  height: 24px;
  text-align: center;
  white-space: nowrap;
  margin: 10px;
}

.carousel {
  display: flex;
  flex-wrap: nowrap;
  max-width: calc(50vw - 220px);
  height: 130px;
  overflow-x: scroll;
}

.carouselItem {
  height: 100px;
  margin: 0 10px;
  cursor: pointer;
}

.activeCarouselItem {
  border: 4px solid #b6d4fe;
}

.loader {
  position: absolute;
  z-index: 1;
  height: 100px;
  width: 100px;
}

.bulkButtons {
  display: flex;
  gap: 20px;
  align-self: flex-end;
  padding-right: 80px;
}
