.container {
  flex: 1;
  height: calc(100vh - 100px);
  display: flex;
}

.right {
  height: 100%;
}

.left {
  height: 100%;
}

.justify {
  flex: 1;
}
