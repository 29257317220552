.focusedInputWrapper {
  position: relative;
}

.inputControl {
  width: 100px;
  position: absolute;
  z-index: 10;
  min-width: 110px;
}

.inputContainer {
  position: relative;
}

.input {
  height: 30px;
  width: 110px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 12px;
  border-radius: .25em;
  border: 1px solid #ced4da;
}

.input::-webkit-scrollbar {
  display: none;
}

.inputHoverable:hover {
  position: absolute;
  z-index: 2;
  width: max-content;
  min-width: 110px;
  background-color: white;
  /* Not the best decision but still looks better */
  padding-left: 10px !important;
}

.token {
  margin-right: 3px;
  white-space: nowrap;
}

.hidden {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  padding: 0.375rem 0.75rem;
  white-space: nowrap;
}
