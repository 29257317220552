.container {
  position: relative;
  min-width: 75px;
}

.input {
  height: 30px;
  font-size: 14px;
}

.option:hover {
  background-color: #f8f9fa;
}
