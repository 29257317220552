.navbar {
  z-index: 2;
}

.pageContent {
  padding: 20px 0;
  width: 100vw;
  overflow-x: hidden;
}

.menuLinkItem {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.menuLinkItem:first-child {
  margin-left: 0;
}

.menuLinkItem:hover {
  color: inherit;
}
