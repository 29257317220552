.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 250px;
}

.icon {
  fill: #747474;
  cursor: pointer;
}

.secondary {
  margin-top: 70px;
  opacity: .3;
}

.disabled {
  opacity: .5;
  pointer-events: none;
}
