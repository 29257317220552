.wrapper {
  position: relative;
}

.wrapper::before {
  content: '$';
  position: absolute;
  left: 4px;
  top: 5px;
  color: #9c9c9c;
}

.wrapper input {
  padding-left: 15px;
}

.wrapper input[type=number] {
  -moz-appearance: textfield;
}

.wrapper input::-webkit-outer-spin-button,
.wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.recentlyUpdated {
  border-color: #198754;
}
