.templateLink {
  text-decoration: none;
  color: inherit;
}

.templateLink:hover {
  color: inherit;
}

.templateName {
  margin: 0;
}

.templateDate {
  margin: 0;
  font-size: 11px;
}
