.container {
  position: relative;
  width: 100%;
}

.input {
  min-width: 130px;
  height: 30px;
  font-size: 14px;
}

.suggest {
  position: absolute;
  width: 400px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ced4da;
  top: 38px;
  box-sizing: border-box;
  padding: 8px 0;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.option {
  cursor: pointer;
  padding: 8px;
}

.selected {
  background-color: #dcffdc;
}

.keyboard {
  background-color: #b5ecf7;
}

.option:hover {
  background-color: #b5ecf7;
}
