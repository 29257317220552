.tableContainer {
  margin-top: 20px;
  padding: 0 10px 600px;
  overflow-x: auto;
  flex-grow: 1;
}

.tableContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 8px;
}

.tableContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.table thead {
  border-top: 2px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
}

.table td {
  padding: .5rem 2px;
  font-size: 14px;
}

.table input:disabled {
  background-color: initial;
}

.deleteButton {
  height: 30px;
}

.counter {
  padding-left: 0.5rem;
  margin-bottom: 0;
}

.disabledRow {
  opacity: .5;
  pointer-events: none;
}

.disabledColumn {
  display: none;
}

.noteInput {
  min-width: 130px;
}
