.button {
  position: fixed;
  z-index: 1000;
  right: 530px;
  top: 17px;
}

.button:hover {
  border: 1px solid black;
}

.minimized {
  right: 190px;
}
