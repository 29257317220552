.button {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  margin-right: 5px;
}

.noText {
  margin: 0;
}
