.row {
  display: flex;
  justify-content: center;
  position: relative;
}

.dots {
  position: absolute;
  bottom: 3px;
}
