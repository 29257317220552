.container {
  position: relative;
}

.inputGroup {
  height: 30px;
  z-index: 2;
}

.inputGroup button {
  background-color: #ffffffba;
}

.inputGroup button:focus {
  box-shadow: none;
}

.inputGroup:hover {
  z-index: 4;
}

.inputGroupWithImage {
  position: absolute;
  width: 60px;
}

.inputGroupDisabled {
  display: none;
}
