.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
}

.container.withHeader {
  height: calc(100vh - 100px);
}

.side {
  width: 50%;
  padding: 20px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}
