.container {
  display: flex;
  align-items: center;
  position: relative;
}

.button {
  cursor: pointer;
  position: absolute;
  left: 0;
  height: 100%;
  width: 20px;
}

.bg {
  background-color: #fff;
  height: calc(100% - 2px);
  margin: 1px 0 0 1px;
  padding: 2px 0 0 2px;
  border-radius: 3px;
}

.bgLocked {
  background-color: #eee;
}
