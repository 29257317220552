.search {
  width: 300px;
  margin: 0 auto 40px;
}

.newTemplateButton {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  right: 20px;
}
