.container {
  display: flex;
  gap: 10px;
  padding-left: 20px;
  flex-wrap: wrap;
}

.input input {
  width: 125px;
}

.button {
  margin-top: 24px;
}

.label {
  font-size: 12px;
}

.percentInputWrapper {
  position: relative;
}

.numberInput {
  width: 100px;
}

.dateInput {
  width: 180px;
}

.percentInput {
  padding-left: 20px;
  max-width: 80px;
}

.percentInputWrapper::before {
  content: "%";
  position: absolute;
  left: 5px;
  top: 30px;
  color: #9c9c9c;
  font-size: 12px;
}
