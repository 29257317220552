.container {
  width: 100px;

  box-sizing: content-box;

  scrollbar-width: thin;

  padding: 0 15px;
}

.rtl {
  direction: rtl;
}

.container::-webkit-scrollbar {
  width: 4px;
  padding: 2px;
}

.container::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.2);
  border-radius: 2px;
}

.image {
  width: 100px;
  margin: 10px 0;
  cursor: pointer;
  scroll-margin: 14px 0;
}

.image.noInteraction {
  cursor: default;
}
