.container {
  position: relative;
}

.smallImage {
  position: relative;
}

.bigImage {
  position: absolute;
  z-index: 5;
  width: 300px;
  min-height: 400px;
  border: 1px solid #000;
  background-color: #fff;
}

.bigImageLeft {
  left: -300px;
}
